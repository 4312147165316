import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, CircularProgress, Chip } from '@mui/material';
import { Cancel, Print as PrintIcon } from '@mui/icons-material';
import { DoctorAppointment } from './models';

interface DoctorDetailsPopupProps {
    open: boolean;
    onClose: () => void;
    doctorName: string;
    roomNumber: string;
    appointments: DoctorAppointment[];
    loading: boolean;
}

export const getWaitTimeClass = (waitTime: number): string => {
    if (waitTime > 45) {
        return 'red';
    } else if (waitTime > 30) {
        return 'yellow';
    } else {
        return 'green';
    }
};

// title casing the name
const titleCase = (name: string) => {
    return name.replace(/\b\w/g, (char) => char.toUpperCase());
};

const DoctorDetailsPopup: React.FC<DoctorDetailsPopupProps> = ({
    open,
    onClose,
    doctorName,
    roomNumber,
    appointments,
    loading
}) => {
    const handlePrint = () => {
        const printContent = document.getElementById('doctor-details-print-content');
        const printWindow = window.open('', '_blank');
        if (printWindow && printContent) {
            printWindow.document.write(`
                <html>
                    <head>
                        <title>${doctorName} - Appointments</title>
                        <style>
                            body { font-family: Arial, sans-serif; color: white; background-color: #1a1a1a; }
                            table { width: 100%; border-collapse: collapse; }
                            th, td { border: 1px solid #444; padding: 8px; text-align: left; }
                            th { background-color: #333; }
                            .chip { background-color: #2196f3; color: white; padding: 2px 6px; border-radius: 12px; font-size: 0.75rem; margin-right: 4px; }
                        </style>
                    </head>
                    <body>
                        <h1>${doctorName} - Room #${roomNumber}</h1>
                        ${printContent.innerHTML}
                    </body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
        }
    };

    const getChipColor = (tag: string) => {
        return tag.toLowerCase() === 'prepaid' ? 'green' : 'purple';
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    backgroundColor: 'black',
                    color: 'white',
                    boxShadow: '0 0 10px rgba(255, 255, 255, 0.1)',
                    filter: 'none', // Ensure the popup is not blurred
                }
            }}
        >
            <DialogTitle>
                <div className="flex justify-between items-center">
                    <span className="text-lg font-semibold">{doctorName}<br />
                        Room #{roomNumber ? roomNumber : `N/A`}</span>
                    <div>
                        <IconButton onClick={handlePrint} size="small" style={{ color: 'white', marginRight: '8px' }}>
                            <PrintIcon />
                        </IconButton>
                        <IconButton onClick={onClose} size="small" style={{ color: 'white' }}>
                            <Cancel />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <CircularProgress style={{ color: 'white' }} />
                    </div>
                ) : (
                    <div className="overflow-x-auto" id="doctor-details-print-content">
                        <table className="w-full min-w-full divide-y divide-gray-500">
                            <thead className="bg-gray-900">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                                        Patient Details
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                                        Appointment
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                                        Wait Time
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                                        Tags
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-gray-800 divide-y divide-gray-700">
                                {appointments.map((appointment, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-md font-medium text-gray-100">{titleCase(appointment.PatientName)}</div>
                                            <div className="text-sm text-gray-200">{appointment.PatientPhone}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-md text-gray-100">{appointment.AppointmentTime}</div>
                                            <div className="text-sm text-gray-200">Checked In: {appointment.CheckedInTime}</div>
                                        </td>
                                        <td className={`px-6 py-4 whitespace-nowrap text-md text-gray-300 ${getWaitTimeClass(appointment.WaitTime)}`}>
                                            {appointment.WaitTime} mins
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {appointment.Tags.map((tag, tagIndex) => (
                                                <Chip
                                                    key={tagIndex}
                                                    label={tag}
                                                    size="small"
                                                    style={{
                                                        backgroundColor: getChipColor(tag),
                                                        color: 'white',
                                                        margin: '2px'
                                                    }}
                                                />
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DoctorDetailsPopup;