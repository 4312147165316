import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchExecutiveData } from '../ipd/fetchData';
import { IPDExecutiveDataResponse } from '../ipd/models';
import { AccessTime, Dashboard, LocalHospital, People } from '@mui/icons-material';
import { getIPDOccupancyClass } from '../ipd/IPDHospitalView';

const formatTime = (minutes: number): string => {
    //  remove decimal part
    const modifiedMinutes = Math.floor(minutes);
    const hours = Math.floor(modifiedMinutes / 60);
    const remainingMinutes = modifiedMinutes % 60;
    return `${hours}h ${remainingMinutes}m`;
};

export const getIPDStayTimeClass = (stayTime: number): string => {
    if (stayTime >= 5) return 'text-red-500';
    if (stayTime >= 4) return 'text-yellow-500';
    return 'text-green-500';
};

export const getEmergencyStayTimeClass = (stayTimeMinutes: number): string => {
    if (stayTimeMinutes >= 360) return 'text-red-500';
    if (stayTimeMinutes >= 240) return 'text-yellow-500';
    return 'text-green-500';
};

const getCardBorderClass = (hospital: any): string => {
    if (getIPDStayTimeClass(hospital.ipd_avg_length_of_stay).includes('red')) {
        return 'border-red-500';
    }
    if (getIPDStayTimeClass(hospital.ipd_avg_length_of_stay).includes('yellow')) {
        return 'border-yellow-500';
    }
    return 'border-green-500';
};

const getOccupancyPercentage = (totalBeds: number, ipdCount: number): number => {
    return Math.round((ipdCount / totalBeds) * 100);
}

const IPDExecutiveDashboard: React.FC = () => {
    const [hospitalStats, setHospitalStats] = useState<IPDExecutiveDataResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const fetchIPDData = async () => {
        setLoading(true);
        try {
            const response = await fetchExecutiveData();
            setHospitalStats(response);
            setError(null);
        } catch (err) {
            console.error('Failed to fetch IPD data:', err);
            setError('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchIPDData();
        const intervalId = setInterval(fetchIPDData, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    if (loading) return <div className="flex justify-center items-center h-screen text-white">Loading...</div>;
    if (error) return <div className="text-red-500 text-center">{error}</div>;

    return (
        <div className="bg-black text-white min-h-screen p-4">
            <header className="bg-gray-900 p-4 rounded-lg shadow-lg mb-6">
                <h1 className="text-3xl font-bold flex items-center">
                    <Dashboard className="mr-2" />
                    IPD Executive Dashboard
                </h1>
            </header>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {hospitalStats.map((hospital) => (
                    <div
                        key={hospital.hospital_id}
                        className={`bg-gray-900 rounded-lg p-4 border-2 ${getCardBorderClass(hospital)} hover:shadow-lg transition duration-300 cursor-pointer`}
                        onClick={() => navigate(`/ipd?branch_id=${hospital.hospital_id}&refreshInterval=5`)}
                    >
                        <h2 className="text-lg font-semibold mb-2 flex items-center">
                            <LocalHospital className="mr-2" />
                            {hospital.hospital_name}
                        </h2>
                        <div className="space-y-2">
                            <p className="flex items-center">
                                <People className="mr-2" />
                                Occupancy:
                                <span className={`font-bold ml-1 ${getIPDOccupancyClass(getOccupancyPercentage(hospital.total_bed_count, hospital.ipd_count))}`}>
                                    {getOccupancyPercentage(hospital.total_bed_count, hospital.ipd_count)}% ({hospital.ipd_count}/{hospital.total_bed_count})
                                </span>
                            </p>
                            <p className="flex items-center">
                                <People className="mr-2" />
                                Emergency Patients: <span className="font-bold ml-1">{hospital.emergency_count}</span>
                            </p>
                            <p className="flex items-center">
                                <AccessTime className="mr-2" />
                                Avg IPD Stay: <span className={`font-bold ml-1 ${getIPDStayTimeClass(hospital.ipd_avg_length_of_stay)}`}>
                                    {hospital.ipd_avg_length_of_stay.toFixed(1)} days
                                </span>
                            </p>
                            <p className="flex items-center">
                                <AccessTime className="mr-2" />
                                Avg Emergency Stay: <span className={`font-bold ml-1 ${getEmergencyStayTimeClass(hospital.emergency_avg_length_of_stay)}`}>
                                    {formatTime(hospital.emergency_avg_length_of_stay)}
                                </span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IPDExecutiveDashboard;