import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@mui/material';
import { LocalHospitalRounded as HospitalIcon } from '@mui/icons-material';
import PatientCommandCenter from './PatientCommandCenter';
import DoctorCommandCenter from './DoctorCommandCenter';
import fetchData from './fetchData';
import { Hospital, AppointmentData, WalkInData, Patient, WaitTimes, Department, AppointmentDistribution, BranchWaitTimes, OPDChamberData, Doctor, FaultyCheckout } from './models';
import { hospitalData } from './PatientCommandCenter';

const CombinedCommandCenter: React.FC = () => {
    const [selectedHospital, setSelectedHospital] = useState<Hospital | null>(null);
    const [showHospitalSelector, setShowHospitalSelector] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [showDoctorView, setShowDoctorView] = useState<boolean>(false);
    const [isAnimating, setIsAnimating] = useState(false);

    // Shared state
    const [appointmentData, setAppointmentData] = useState<AppointmentData>({ all: 0, checkedIn: 0, cancelled: 0, checkedOut: 0, avgWaitTime: 0, avgCheckedInWaitTime: 0, waitCount: 0 });
    const [walkInData, setWalkInData] = useState<WalkInData>({ count: 0, percentage: '0%', avgWaitTime: 0, avgCheckedInWaitTime: 0, waitCount: 0 });

    // Patient-specific state
    const [patients, setPatients] = useState<Patient[]>([]);
    const [waitTimes, setWaitTimes] = useState<WaitTimes>({ greaterThan45: 0, greaterThan30: 0, greaterThan15: 0, total: 0 });
    const [departments, setDepartments] = useState<Department[]>([]);
    const [appointmentDistribution, setAppointmentDistribution] = useState<AppointmentDistribution[]>([]);
    const [yesterdayAppointmentDistribution, setYesterdayAppointmentDistribution] = useState<AppointmentDistribution[]>([]);
    const [sevenDaysAgoAppointmentDistribution, setSevenDaysAgoAppointmentDistribution] = useState<AppointmentDistribution[]>([]);
    const [branchWaitTimes, setBranchWaitTimes] = useState<BranchWaitTimes[]>([]);
    const [yesterdayBranchWaitTimes, setYesterdayBranchWaitTimes] = useState<BranchWaitTimes[]>([]);
    const [sevenDaysAgoBranchWaitTimes, setSevenDaysAgoBranchWaitTimes] = useState<BranchWaitTimes[]>([]);
    const [faultyCheckouts, setFaultyCheckouts] = useState<FaultyCheckout[]>([]);

    // Doctor-specific state
    const [doctors, setDoctors] = useState<Doctor[]>([]);
    const [opdData, setOpdData] = useState<OPDChamberData>({ doctorAway: 0, doctorIn: 0, vacant: 0, assignments: [], inDoctors: [] });

    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const branchId = urlParams.get('branch_id') || '9757a411-668d-4261-bf93-bb97132cde6a';
        const hospital = hospitalData.find(h => h.id === branchId) || hospitalData[0];
        setSelectedHospital(hospital);
    }, []);

    useEffect(() => {
        const fetchAndUpdateData = async () => {
            if (!selectedHospital) return;

            try {
                const urlParams = new URLSearchParams(window.location.search);
                const service_type = urlParams.get('service_type') || '310c9aa2-ec2f-4278-b92f-6e60eabbc7bc';

                const data = await fetchData(selectedHospital.id, service_type);

                // Update shared state
                setAppointmentData(data.appointmentData);
                setWalkInData(data.walkInData);

                // Update patient-specific state
                setPatients(data.patients);
                setWaitTimes(data.waitTimes);
                setDepartments(data.departments);
                setAppointmentDistribution(data.appointmentDistribution);
                setYesterdayAppointmentDistribution(data.yesterdayAppointmentDistribution);
                setSevenDaysAgoAppointmentDistribution(data.sevenDaysAgoAppointmentDistribution);
                setBranchWaitTimes(data.branchWaitTimes);
                setYesterdayBranchWaitTimes(data.yesterdayBranchWaitTimes);
                setSevenDaysAgoBranchWaitTimes(data.sevenDaysAgoBranchWaitTimes);
                setFaultyCheckouts(filterFaultyCheckouts(data.faultyCheckouts));

                // Update doctor-specific state
                setDoctors(data.doctors);
                setOpdData(data.opdData);

                setError(null);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchAndUpdateData();

        const intervalId = setInterval(fetchAndUpdateData, 5 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [selectedHospital]);

    const handleHospitalSelect = (hospital: Hospital) => {
        setSelectedHospital(hospital);
        setShowHospitalSelector(false);
        setLoading(true);
        navigate(`/opd?branch_id=${hospital.id}`);
    };

    const handleToggleView = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setShowDoctorView(!showDoctorView);
            setTimeout(() => setIsAnimating(false), 300);
        }, 500);
    };

    const filterFaultyCheckouts = (faultyCheckouts: FaultyCheckout[]) => {
        return faultyCheckouts.filter(checkout => checkout.checkout_by.toLowerCase() !== 'medi internal user');
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen bg-black">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }

    return (
        <div className="bg-black text-white min-h-screen">
            <header className="bg-gray-900 p-4 shadow-lg mb-4 flex justify-between items-center">
                <h1 className="text-xl sm:text-3xl font-bold flex items-center cursor-pointer" onClick={() => setShowHospitalSelector(true)}>
                    <HospitalIcon className="mr-2" />
                    {selectedHospital?.name || 'Select Hospital'}
                </h1>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showDoctorView}
                            onChange={handleToggleView}
                            color="primary"
                        />
                    }
                    label={showDoctorView ? "Doctor View" : "Patient View"}
                />
            </header>

            <div className={`transition-all duration-500 ease-in-out ${isAnimating ? 'opacity-0 transform' : 'opacity-100 transform'}`}>
                {showDoctorView ? (
                    <DoctorCommandCenter
                        selectedHospital={selectedHospital}
                        appointmentData={appointmentData}
                        walkInData={walkInData}
                        doctors={doctors}
                        opdData={opdData}
                        faultyCheckouts={faultyCheckouts}
                    />
                ) : (
                    <PatientCommandCenter
                        appointmentData={appointmentData}
                        walkInData={walkInData}
                        patients={patients}
                        waitTimes={waitTimes}
                        departments={departments}
                        appointmentDistribution={appointmentDistribution}
                        yesterdayAppointmentDistribution={yesterdayAppointmentDistribution}
                        sevenDaysAgoAppointmentDistribution={sevenDaysAgoAppointmentDistribution}
                        branchWaitTimes={branchWaitTimes}
                        yesterdayBranchWaitTimes={yesterdayBranchWaitTimes}
                        sevenDaysAgoBranchWaitTimes={sevenDaysAgoBranchWaitTimes}
                        faultyCheckouts={faultyCheckouts}
                    />
                )}
            </div>

            <Dialog open={showHospitalSelector} onClose={() => setShowHospitalSelector(false)}>
                <DialogTitle>Select Hospital</DialogTitle>
                <DialogContent>
                    <List>
                        {hospitalData.map((hospital) => (
                            <ListItem button key={hospital.id} onClick={() => handleHospitalSelect(hospital)}>
                                <ListItemText primary={hospital.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default CombinedCommandCenter;