import { AppointmentData, WalkInData, Patient, WaitTimes, Department, Doctor, AppointmentDistribution, OPDChamberData, BranchWaitTimes, FaultyCheckout } from './models';

interface FetchDataResponse {
    appointmentData: AppointmentData;
    walkInData: WalkInData;
    patients: Patient[];
    waitTimes: WaitTimes;
    departments: Department[];
    doctors: Doctor[];
    appointmentDistribution: AppointmentDistribution[];
    yesterdayAppointmentDistribution: AppointmentDistribution[];
    sevenDaysAgoAppointmentDistribution: AppointmentDistribution[];
    opdData: OPDChamberData;
    branchWaitTimes: BranchWaitTimes[];
    yesterdayBranchWaitTimes: BranchWaitTimes[];
    sevenDaysAgoBranchWaitTimes: BranchWaitTimes[];
    faultyCheckouts: FaultyCheckout[];
}

const fetchData = async (
    branchId: string,
    serviceTypeId: string,
): Promise<FetchDataResponse> => {

    // const url = `http://localhost:8000/get-opd-data?serviceId=${serviceTypeId}&branchId=${branchId}`;
    const url = `https://analytics-beta.maxhealthcaredigital.com/get-opd-data?serviceId=${serviceTypeId}&branchId=${branchId}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: FetchDataResponse = await response.json();
        console.log('Data fetched:', data);

        return data;

    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export default fetchData;