import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface HospitalWaitTime {
    name: string;
    waitTime: number;
}

export interface HourlyDataItem {
    time: string;
    [timeSlot: string]: string | HospitalWaitTime[];
    hospitals: HospitalWaitTime[];
}

interface HourlyViewPopupProps {
    hourlyData: HourlyDataItem[];
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const HourlyOPDViewPopup: React.FC<HourlyViewPopupProps> = ({ hourlyData }) => {
    const timeSlots = ['9-10', '10-11', '11-12', '12-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8'];
    const units = Array.from(new Set(hourlyData.flatMap(item => item.hospitals.map(h => h.name))));

    const getStatusColor = (waitTime: number | null): string => {
        if (waitTime === null) {
            return '#9C27B0'; // Purple for missing data
        } else if (waitTime === 0) {
            return '#424242'; // Dark grey for the dark theme
        } else if (waitTime > 45) {
            return 'red'; // Red
        } else if (waitTime > 30) {
            return '#F59E0B'; // Amber
        } else {
            return 'green'; // Green
        }
    };

    const getStatus = (waitTime: number | null): string => {
        if (waitTime === null) {
            return 'M'; // M for Missing
        } else if (waitTime === 0) {
            return '';
        } else if (waitTime > 45) {
            return 'R';
        } else if (waitTime > 30) {
            return 'A';
        } else {
            return 'G';
        }
    };

    const calculatePercentages = (unit: string) => {
        const unitData = hourlyData.flatMap(item =>
            item.hospitals.filter(h => h.name === unit && h.waitTime > 0).map(h => h.waitTime)
        );
        const totalSlots = unitData.length;
        const greenCount = unitData.filter(waitTime => getStatus(waitTime) === 'G').length;
        const amberCount = unitData.filter(waitTime => getStatus(waitTime) === 'A').length;
        const redCount = unitData.filter(waitTime => getStatus(waitTime) === 'R').length;

        return {
            G: totalSlots > 0 ? Math.round((greenCount / totalSlots) * 100) : 0,
            A: totalSlots > 0 ? Math.round((amberCount / totalSlots) * 100) : 0,
            R: totalSlots > 0 ? Math.round((redCount / totalSlots) * 100) : 0
        };
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Unit</TableCell>
                            {timeSlots.map((slot, index) => (
                                <TableCell
                                    key={slot}
                                    style={{
                                        borderRight: index < timeSlots.length - 1 ? '1px dotted rgba(255, 255, 255, 0.3)' : 'none'
                                    }}
                                >
                                    {slot}
                                </TableCell>
                            ))}
                            <TableCell>G</TableCell>
                            <TableCell>A</TableCell>
                            <TableCell>R</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {units.map(unit => {
                            const percentages = calculatePercentages(unit);
                            return (
                                <TableRow key={unit}>
                                    <TableCell component="th" scope="row">{unit}</TableCell>
                                    {timeSlots.map((slot, index) => {
                                        const hourData = hourlyData.find(data => data.time === slot);
                                        const hospitalData = hourData?.hospitals.find(h => h.name === unit);
                                        const waitTime = hospitalData ? hospitalData.waitTime : null;
                                        const status = getStatus(waitTime);
                                        const backgroundColor = getStatusColor(waitTime);
                                        return (
                                            <TableCell
                                                key={`${unit}-${slot}`}
                                                style={{
                                                    backgroundColor,
                                                    color: backgroundColor === '#424242' ? '#fff' : '#000',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    borderRight: index < timeSlots.length - 1 ? '1px dotted rgba(255, 255, 255, 0.3)' : 'none'
                                                }}
                                            >
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell>{percentages.G}%</TableCell>
                                    <TableCell>{percentages.A}%</TableCell>
                                    <TableCell>{percentages.R}%</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>
    );
};

export default HourlyOPDViewPopup;