import React, { useState, useMemo } from 'react';
import { FaultyCheckout } from './models';
import { Print } from '@mui/icons-material';

interface FaultyCheckoutDetailsProps {
    checkouts: FaultyCheckout[];
}

const ITEMS_PER_PAGE = 50;

const formatName = (name: string): string => {
    return name.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
};

const formatTime = (time: string, addOffset: boolean = false): string => {
    if (!time) return 'N/A';
    const date = new Date(`1970-01-01T${time}`);
    if (addOffset) {
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
    }
    return date.toTimeString().split(':').slice(0, 2).join(':');
};

const isIrregularCheckout = (checkout: FaultyCheckout): boolean => {
    if (!checkout.prescription_ready_time) return false;
    const prescriptionTime = new Date(`1970-01-01T${checkout.prescription_ready_time}`);
    const consultEndTime = new Date(`1970-01-01T${checkout.consult_end_time}`);
    return prescriptionTime.getTime() - consultEndTime.getTime() > 5 * 60 * 1000; // 30 minutes
};

const FaultyCheckoutDetails: React.FC<FaultyCheckoutDetailsProps> = ({ checkouts }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState<'DELAYED PRESCRIPTION' | 'CHECKOUTS WITHOUT EP' | 'DELAYED PRESCRIPTION SUMMARY'>('DELAYED PRESCRIPTION');

    const sortedCheckouts = useMemo(() => {
        return [...checkouts]
            .sort((a, b) => {
                const timeA = new Date(`1970-01-01T${a.patient_checkedin_time}`);
                const timeB = new Date(`1970-01-01T${b.patient_checkedin_time}`);
                return timeB.getTime() - timeA.getTime();
            });
    }, [checkouts]);

    const filteredCheckouts = useMemo(() => {
        switch (activeTab) {
            case 'DELAYED PRESCRIPTION':
                return sortedCheckouts.filter(checkout => checkout.prescription_ready_time !== null);
            case 'CHECKOUTS WITHOUT EP':
                return sortedCheckouts.filter(checkout => checkout.prescription_ready_time === null);
            case 'DELAYED PRESCRIPTION SUMMARY':
                return sortedCheckouts.filter(isIrregularCheckout);
            default:
                return sortedCheckouts;
        }
    }, [sortedCheckouts, activeTab]);

    const irregularCheckoutsByUser = useMemo(() => {
        const irregularCheckouts = sortedCheckouts.filter(isIrregularCheckout);
        const countByUser: { [key: string]: number } = {};
        irregularCheckouts.forEach(checkout => {
            const username = checkout.checkout_by;
            countByUser[username] = (countByUser[username] || 0) + 1;
        });
        return Object.entries(countByUser).sort((a, b) => b[1] - a[1]);
    }, [sortedCheckouts]);

    const totalPages = Math.ceil(filteredCheckouts.length / ITEMS_PER_PAGE);
    const paginatedCheckouts = filteredCheckouts.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const withPrescriptionCount = useMemo(() =>
        sortedCheckouts.filter(checkout => checkout.prescription_ready_time !== null).length,
        [sortedCheckouts]);

    const withoutPrescriptionCount = useMemo(() =>
        sortedCheckouts.filter(checkout => checkout.prescription_ready_time === null).length,
        [sortedCheckouts]);

    const irregularCheckoutsCount = useMemo(() =>
        sortedCheckouts.filter(isIrregularCheckout).length,
        [sortedCheckouts]);

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
            let tableContent = '';

            if (activeTab === 'DELAYED PRESCRIPTION SUMMARY') {
                tableContent = irregularCheckoutsByUser.map(([username, count], index) => `
                    <tr>
                        <td>${index + 1}</td>
                        <td>${formatName(username)}</td>
                        <td>${count}</td>
                    </tr>
                `).join('');
            } else {
                tableContent = filteredCheckouts.map((checkout, index) => `
                    <tr>
                        <td>${formatName(checkout.patient_name)}</td>
                        <td>${formatTime(checkout.patient_checkedin_time)}</td>
                        <td>${formatName(checkout.checkout_by)}</td>
                        <td>${formatName(checkout.doctor_name)}</td>
                        <td>
                            <div>Start: ${formatTime(checkout.consult_start_time, true)}</div>
                            <div>End: ${formatTime(checkout.consult_end_time, true)}</div>
                        </td>
                        <td>${formatTime(checkout.prescription_ready_time, true)}</td>
                    </tr>
                `).join('');
            }

            const tableHeaders = activeTab === 'DELAYED PRESCRIPTION SUMMARY'
                ? '<tr><th>Rank</th><th>Username</th><th>DELAYED PRESCRIPTION SUMMARY</th></tr>'
                : '<tr><th>Patient</th><th>Checked In</th><th>Checkout By</th><th>Doctor</th><th>Consult Time</th><th>Prescription</th></tr>';

            printWindow.document.write(`
                <html>
                    <head>
                        <title>Appointments - ${activeTab}</title>
                        <style>
                            body { font-family: Arial, sans-serif; color: black; background-color: white; }
                            table { width: 100%; border-collapse: collapse; }
                            th, td { border: 1px solid #444; padding: 8px; text-align: left; }
                            th { background-color: #f2f2f2; }
                        </style>
                    </head>
                    <body>
                        <h1>Appointments - ${activeTab}</h1>
                        <table>
                            <thead>
                                ${tableHeaders}
                            </thead>
                            <tbody>
                                ${tableContent}
                            </tbody>
                        </table>
                    </body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
        }
    };

    return (
        <div className="flex flex-col h-full bg-gray-900 text-white">
            <div className="flex mb-4 bg-gray-800">
                {[
                    { name: 'DELAYED PRESCRIPTION', count: withPrescriptionCount },
                    { name: 'CHECKOUTS WITHOUT EP', count: withoutPrescriptionCount },
                    { name: 'DELAYED PRESCRIPTION SUMMARY' }
                ].map((tab) => (
                    <button
                        key={tab.name}
                        className={`px-4 py-2 ${activeTab === tab.name ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
                        onClick={() => setActiveTab(tab.name as 'DELAYED PRESCRIPTION' | 'CHECKOUTS WITHOUT EP' | 'DELAYED PRESCRIPTION SUMMARY')}
                    >
                        {tab.name} {`${tab.count ? `(${tab.count})` : ''}`}
                    </button>
                ))}
                <button
                    className="px-4 py-2 text-white rounded ml-auto"
                    onClick={handlePrint}
                >
                    <Print />
                </button>
            </div>
            <div className="flex-grow overflow-auto">
                {activeTab === 'DELAYED PRESCRIPTION SUMMARY' ? (
                    <table className="w-full">
                        <thead className="bg-gray-800 sticky top-0">
                            <tr>
                                <th className="p-2 text-left">S. No.</th>
                                <th className="p-2 text-left">Username</th>
                                <th className="p-2 text-left">Irregular Checkouts</th>
                            </tr>
                        </thead>
                        <tbody>
                            {irregularCheckoutsByUser.map(([username, count], index) => (
                                <tr key={username} className={`border-b border-gray-700 ${index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'}`}>
                                    <td className="p-2">{index + 1}</td>
                                    <td className="p-2">{formatName(username)}</td>
                                    <td className="p-2">{count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <table className="w-full">
                        <thead className="bg-gray-800 sticky top-0">
                            <tr>
                                <th className="p-2 text-left">Patient</th>
                                <th className="p-2 text-left">Checked In</th>
                                <th className="p-2 text-left">Checkout By</th>
                                <th className="p-2 text-left">Doctor</th>
                                <th className="p-2 text-left">Consult Time</th>
                                <th className="p-2 text-left">Prescription</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedCheckouts.map((checkout, index) => (
                                <tr key={index} className={`border-b border-gray-700 ${index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'}`}>
                                    <td className="p-2">
                                        {formatName(checkout.patient_name)} <br />
                                        {/* smaller text for mobile */}
                                        <span className="text-sm">{checkout.mobile}</span>
                                    </td>
                                    <td className="p-2">{formatTime(checkout.patient_checkedin_time)}</td>
                                    <td className="p-2">{formatName(checkout.checkout_by)}</td>
                                    <td className="p-2">{formatName(checkout.doctor_name)}</td>
                                    <td className="p-2">
                                        <div>Start: {formatTime(checkout.consult_start_time, true)}</div>
                                        <div>End: {formatTime(checkout.consult_end_time, true)}</div>
                                    </td>
                                    <td className="p-2">{formatTime(checkout.prescription_ready_time, true)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="flex justify-center items-center mt-4 bg-gray-800 p-2">
                <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1 || activeTab === 'DELAYED PRESCRIPTION SUMMARY'}
                    className="px-4 py-2 bg-gray-700 text-white rounded mr-2 disabled:opacity-50"
                >
                    Previous
                </button>
                <span className="mx-4">
                    {activeTab === 'DELAYED PRESCRIPTION SUMMARY'
                        ? `Showing ${irregularCheckoutsByUser.length} users`
                        : `Page ${currentPage} of ${totalPages}`
                    }
                </span>
                <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages || activeTab === 'DELAYED PRESCRIPTION SUMMARY'}
                    className="px-4 py-2 bg-gray-700 text-white rounded ml-2 disabled:opacity-50"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default FaultyCheckoutDetails;