import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import {
    AppBar, Toolbar, Typography, Container, Grid, Card, CardContent,
    Button, TextField, Tab, Tabs, Box, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Drawer, List, ListItem,
    ListItemIcon, ListItemText, IconButton, Collapse, Fade, Zoom
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    Dashboard as DashboardIcon,
    AttachMoney as RevenueIcon,
    TrendingUp as LeadsIcon,
    ShoppingCart as OrdersIcon,
    Timeline as MetricIcon,
    Menu as MenuIcon,
    Add as AddIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import dayjs from 'dayjs';
import { DailyMetricInput, DailyMetricOutput, metricFields } from './models';

const BASE_URL = 'https://analytics-beta.maxhealthcaredigital.com';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#64B5F6', // A softer blue
        },
        secondary: {
            main: '#81C784', // A muted green
        },
        background: {
            default: '#1A2027', // A deep blue-gray
            paper: '#252C35', // A slightly lighter blue-gray
        },
        text: {
            primary: '#E0E0E0', // Light gray for better readability
            secondary: '#B0BEC5', // A muted blue-gray for secondary text
        },
        error: {
            main: '#FF8A65', // A softer orange-red for errors
        },
        warning: {
            main: '#FFD54F', // A muted yellow for warnings
        },
        info: {
            main: '#4FC3F7', // A light blue for info
        },
        success: {
            main: '#81C784', // A muted green for success
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 500,
        },
        h5: {
            fontWeight: 500,
        },
        h6: {
            fontWeight: 500,
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    borderRadius: '12px',
                    background: 'linear-gradient(145deg, #252C35, #2A3340)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1E2730', // Slightly darker than background
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#1E2730', // Match AppBar color
                },
            },
        },
    },
});
const MotionCard = motion(Card);
const MotionGrid = motion(Grid);

const FinanceDashboard = () => {
    const [metrics, setMetrics] = useState<DailyMetricOutput[]>([]);
    const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs().subtract(30, 'day'));
    const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs());
    const [selectedMetric, setSelectedMetric] = useState<keyof DailyMetricOutput>('app_revenue');
    const [newMetric, setNewMetric] = useState<DailyMetricInput>({ date: dayjs().format('YYYY-MM-DD') });
    const [tabValue, setTabValue] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputSectionOpen, setInputSectionOpen] = useState(false);
    useEffect(() => {
        if (startDate && endDate) {
            fetchMetrics();
        }
    }, [startDate, endDate]);

    const fetchMetrics = async () => {
        if (!startDate || !endDate) return;
        try {
            const url = `${BASE_URL}/daily-metrics?start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data: DailyMetricOutput[] = await response.json();
            setMetrics(data);
        } catch (error) {
            console.error('Error fetching metrics:', error);
        }
    };

    const submitNewMetric = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const url = `${BASE_URL}/daily-metrics`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(newMetric),
            });
            if (response.ok) {
                fetchMetrics();
                setNewMetric({ date: dayjs().format('YYYY-MM-DD') });
            }
        } catch (error) {
            console.error('Error submitting new metric:', error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewMetric(prev => ({ ...prev, [name]: name === 'date' ? value : Number(value) }));
    };

    const aggregateMetrics = useMemo(() => {
        return metrics.reduce((acc, metric) => {
            (Object.keys(metric) as Array<keyof DailyMetricOutput>).forEach((key) => {
                const value = Number(metric[key]);
                if (typeof value === 'number') {
                    acc[key] = (acc[key] || 0) + value;
                }
            });
            return acc;
        }, {} as Record<keyof DailyMetricOutput, number>);
    }, [metrics]);

    const renderMetricCard = (title: string, value: number | undefined, icon: React.ReactNode) => (
        <MotionGrid
            item xs={12} sm={6} md={3}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <MotionCard whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                        {icon}
                        <Typography variant="h6" component="div" ml={1}>
                            {title}
                        </Typography>
                    </Box>
                    <Typography variant="h4" component="div">
                        {typeof value === 'number' ? value.toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Aggregated over date range
                    </Typography>
                </CardContent>
            </MotionCard>
        </MotionGrid>
    );

    const renderChart = (data: DailyMetricOutput[], dataKeys: (keyof DailyMetricOutput)[], chartType: 'line' | 'bar') => (
        <Fade in={true} timeout={1000}>
            <Box>
                <ResponsiveContainer width="100%" height={400}>
                    {chartType === 'line' ? (
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} />
                            <Legend />
                            {dataKeys.map((key, index) => (
                                <Line
                                    key={key}
                                    type="monotone"
                                    dataKey={key}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth={2}
                                    dot={{ r: 4 }}
                                    activeDot={{ r: 8 }}
                                />
                            ))}
                        </LineChart>
                    ) : (
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip contentStyle={{ backgroundColor: theme.palette.background.paper }} />
                            <Legend />
                            {dataKeys.map((key, index) => (
                                <Bar
                                    key={key}
                                    dataKey={key}
                                    fill={theme.palette.primary.main}
                                    animationBegin={index * 200}
                                    animationDuration={800}
                                />
                            ))}
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </Box>
        </Fade>
    );

    const renderTable = (data: DailyMetricOutput[], columns: (keyof DailyMetricOutput)[]) => (
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
            <TableContainer component={Paper} sx={{ mt: 2, boxShadow: 'none', backgroundColor: 'transparent' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            {columns.map((column) => (
                                <TableCell key={column}>{column}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={row.date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{row.date}</TableCell>
                                {columns.map((column) => (
                                    <TableCell key={column}>{row[column]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Zoom>
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex' }}>
                    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => setDrawerOpen(!drawerOpen)}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                Finance Dashboard
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: 240,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
                            display: { xs: 'none', sm: 'block' },
                        }}
                    >
                        <Toolbar />
                        <Box sx={{ overflow: 'auto' }}>
                            <List>
                                {['Overview', 'Revenue', 'Leads', 'Orders', 'Metric Explorer'].map((text, index) => (
                                    <ListItem button key={text} onClick={() => setTabValue(index)}>
                                        <ListItemIcon>
                                            {index === 0 ? <DashboardIcon /> :
                                                index === 1 ? <RevenueIcon /> :
                                                    index === 2 ? <LeadsIcon /> :
                                                        index === 3 ? <OrdersIcon /> :
                                                            <MetricIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Toolbar />
                        <Container maxWidth="lg">
                            <Grid container spacing={3} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                    />
                                </Grid>
                            </Grid>

                            <AnimatePresence>
                                {tabValue === 0 && (
                                    <MotionGrid
                                        container spacing={3}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        {renderMetricCard("Total Revenue", aggregateMetrics.overall_revenue, <RevenueIcon color="primary" />)}
                                        {renderMetricCard("App Revenue", aggregateMetrics.app_revenue, <RevenueIcon color="secondary" />)}
                                        {renderMetricCard("Total Leads", aggregateMetrics.total_leads, <LeadsIcon color="primary" />)}
                                        {renderMetricCard("Cart Orders", aggregateMetrics.cart_orders, <OrdersIcon color="secondary" />)}
                                    </MotionGrid>
                                )}
                            </AnimatePresence>

                            {tabValue === 1 && (
                                <MotionCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>Revenue Breakdown</Typography>
                                        {renderChart(metrics, ['app_revenue', 'overall_revenue', 'in_hospital_revenue'], 'bar')}
                                        {renderTable(metrics, ['date', 'app_revenue', 'overall_revenue', 'in_hospital_revenue'])}
                                    </CardContent>
                                </MotionCard>
                            )}

                            {tabValue === 2 && (
                                <MotionCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>Lead Trends</Typography>
                                        {renderChart(metrics, ['app_leads', 'mah_digicare_leads', 'mah_cc_leads', 'total_leads'], 'line')}
                                        {renderTable(metrics, ['date', 'app_leads', 'mah_digicare_leads', 'mah_cc_leads', 'total_leads'])}
                                    </CardContent>
                                </MotionCard>
                            )}

                            {tabValue === 3 && (
                                <MotionCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>Order Analytics</Typography>
                                        {renderChart(metrics, ['cart_orders', 'in_hospital_order_count', 'converted_orders', 'cart_converted'], 'line')}
                                        {renderTable(metrics, ['date', 'cart_orders', 'in_hospital_order_count', 'converted_orders', 'cart_converted'])}
                                    </CardContent>
                                </MotionCard>
                            )}

                            {tabValue === 4 && (
                                <MotionCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>Metric Explorer</Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                                            {Object.keys(metrics[0] || {}).map((key) => (
                                                <Button
                                                    key={key}
                                                    variant={selectedMetric === key ? 'contained' : 'outlined'}
                                                    onClick={() => setSelectedMetric(key as keyof DailyMetricOutput)}
                                                >
                                                    {key}
                                                </Button>
                                            ))}
                                        </Box>
                                        {renderChart(metrics, [selectedMetric], 'line')}
                                        {renderTable(metrics, ['date', selectedMetric])}
                                    </CardContent>
                                </MotionCard>
                            )}

                            <MotionCard
                                sx={{ mt: 3 }}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setInputSectionOpen(!inputSectionOpen)}
                                    >
                                        <Typography variant="h6">Add New Daily Metric</Typography>
                                        <IconButton>
                                            {inputSectionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box>
                                    <Collapse in={inputSectionOpen}>
                                        <form onSubmit={submitNewMetric}>
                                            <Grid container spacing={2} sx={{ mt: 2 }}>
                                                {metricFields.map(({ key, label, type }) => (
                                                    <Grid item xs={12} sm={6} md={4} key={key}>
                                                        <TextField
                                                            fullWidth
                                                            type={type}
                                                            name={key}
                                                            label={label}
                                                            value={newMetric[key] || ''}
                                                            onChange={handleInputChange}
                                                            inputProps={type === 'number' ? { min: -1, step: 1 } : {}}
                                                            required={key === 'date'}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                sx={{ mt: 2 }}
                                            >
                                                Submit New Metric
                                            </Button>
                                        </form>
                                    </Collapse>
                                </CardContent>
                            </MotionCard>
                        </Container>
                    </Box>
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default FinanceDashboard;



