import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import App from './App';
import ExecutiveDashboard from './executive_view/ExecutiveDashboard';
import Login from './login';
import ProtectedRoute from './protectedRoute';
import IPDExecutiveDashboard from './executive_view/ExecutiveIPDDashboard';
import HospitalIPDView from './ipd/IPDHospitalView';
import CombinedCommandCenter from './opd/CommandCenter';
import FinanceDashboard from './financial/FinanceDashboard';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        } />
        <Route path="/opd" element={
          <ProtectedRoute>
            <CombinedCommandCenter />
          </ProtectedRoute>
        } />
        <Route path="/ipd" element={
          <ProtectedRoute>
            <HospitalIPDView />
          </ProtectedRoute>
        } />
        <Route path="/executive" element={
          <ProtectedRoute>
            <ExecutiveDashboard />
          </ProtectedRoute>
        } />
        <Route path="/ipd-executive" element={
          <ProtectedRoute>
            <IPDExecutiveDashboard />
          </ProtectedRoute>
        } />
        <Route path="/app-finance" element={
          <ProtectedRoute>
            <FinanceDashboard />
          </ProtectedRoute>
        } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();